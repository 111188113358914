<template>
  <div>
    <AddCompany v-if="task === 'add-company'" />
  </div>
</template>

<script>
import AddCompany from "@/components/cookpit/AddCompany";

export default {
  components: {
    AddCompany,
  },
  data() {
    return {
      task: this.$route.params.task,
    }
  },
  created() {
  },
  methods: {
  },
}
</script>

<style>

</style>
