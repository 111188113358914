var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("Add company details")]),_c('p',[_vm._v(" We need some basic data from you ")]),_c('validation-observer',{ref:"companyRegisterValidation",attrs:{"name":"RegisterCompany"}},[_c('b-form',{on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('b-card',[_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"domicile-country-group","label":"Domicile country:","label-for":"domicile-country"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"domicile-country","options":_vm.countries,"state":errors.length > 0 ? false:null,"required":"","disabled":_vm.disableInputs},model:{value:(_vm.country_id),callback:function ($$v) {_vm.country_id=$$v},expression:"country_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"id":"company-name-group","label":"Company Name","label-for":"company-name"}},[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.companies[_vm.company_name]!=null)?_c('b-button',{on:{"click":function($event){return _vm.getCompanyDataFromZefix()}}},[_c('feather-icon',{attrs:{"icon":"DownloadIcon"}}),_vm._v(" Get Data ")],1):_vm._e()]},proxy:true}])},[_c('b-form-input',{attrs:{"autocomplete":"off","id":"company-name","required":"","list":"companies-list","disabled":_vm.disableInputs},on:{"keyup":function($event){return _vm.searchForCompaniesName()}},model:{value:(_vm.company_name),callback:function ($$v) {_vm.company_name=$$v},expression:"company_name"}})],1),_c('div',{staticClass:"mt-2"},[_vm._v("Company UID: \""+_vm._s(_vm.companies[_vm.company_name])+"\"")]),_c('datalist',{attrs:{"id":"companies-list"}},_vm._l((_vm.companies),function(company,index){return _c('option',[_vm._v(_vm._s(index))])}),0)],1)],1),_c('b-col',{attrs:{"cols":"6"}},[(_vm.showLoader)?_c('img',{attrs:{"src":"/loader.svg"}}):_vm._e(),_vm._v(" "+_vm._s(JSON.stringify(_vm.company_zefix_data))+" ")])],1)],1),_c('b-card',[_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"company-name-field-group","label":"Company name:","label-for":"company-name-field"}},[_c('validation-provider',{attrs:{"name":"Company Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-name-field","required":"","state":errors.length > 0 ? false:null,"disabled":_vm.disableInputs},model:{value:(_vm.company_name),callback:function ($$v) {_vm.company_name=$$v},expression:"company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"id":"company-address-group","label":"Street name:","label-for":"company-address"}},[_c('validation-provider',{attrs:{"name":"Company Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-address","state":errors.length > 0 ? false:null,"required":"","disabled":_vm.disableInputs},model:{value:(_vm.company_address),callback:function ($$v) {_vm.company_address=$$v},expression:"company_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"id":"company-address-nr-group","label":"Nr:","label-for":"company-address-nr"}},[_c('validation-provider',{attrs:{"name":"Company Address Nr","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-address-nr","required":"","state":errors.length > 0 ? false:null,"disabled":_vm.disableInputs},model:{value:(_vm.company_address_nr),callback:function ($$v) {_vm.company_address_nr=$$v},expression:"company_address_nr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"company-uid-group","label":"Company UID (CHE-397.517.219 > CHE397517219):","label-for":"company-uid"}},[_c('validation-provider',{attrs:{"name":"Company UID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-uid","required":"","state":errors.length > 0 ? false:null,"disabled":_vm.disableInputs},model:{value:(_vm.company_uid),callback:function ($$v) {_vm.company_uid=$$v},expression:"company_uid"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"id":"company-zip-group","label":"Zip:","label-for":"company-zip"}},[_c('validation-provider',{attrs:{"name":"Company Zip","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-zip","required":"","state":errors.length > 0 ? false:null,"disabled":_vm.disableInputs},model:{value:(_vm.company_zip),callback:function ($$v) {_vm.company_zip=$$v},expression:"company_zip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"id":"company-city-group","label":"City:","label-for":"company-city"}},[_c('validation-provider',{attrs:{"name":"Company City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-city","required":"","state":errors.length > 0 ? false:null,"disabled":_vm.disableInputs},model:{value:(_vm.company_city),callback:function ($$v) {_vm.company_city=$$v},expression:"company_city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"company-legal-form-group","label":"Legal Form:","label-for":"company-legal-form"}},[_c('validation-provider',{attrs:{"name":"Company Legal Form","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"company-legal-form","options":_vm.legal_forms,"required":"","state":errors.length > 0 ? false:null,"disabled":_vm.disableInputs},model:{value:(_vm.company_legal_form),callback:function ($$v) {_vm.company_legal_form=$$v},expression:"company_legal_form"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"company-website-group","label":"Website:","label-for":"company-website"}},[_c('b-form-input',{attrs:{"id":"company-website","required":""},model:{value:(_vm.company_website),callback:function ($$v) {_vm.company_website=$$v},expression:"company_website"}})],1)],1)],1)],1),_c('h5',[_vm._v("Financial Data")]),_c('b-card',{staticStyle:{"background":"#fefefe"}},[_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"company-established-at-group","label":"Established since:","label-for":"company-established-at-field"}},[_c('validation-provider',{attrs:{"name":"Established since","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"id":"company-established-at-field","state":errors.length > 0 ? false:null,"disabled":_vm.disableInputs,"required":""},model:{value:(_vm.company_established_at),callback:function ($$v) {_vm.company_established_at=$$v},expression:"company_established_at"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"company-number-of-employees-group","label":"Number of employees:","label-for":"company-number-of-employees"}},[_c('validation-provider',{attrs:{"name":"Number of employees","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-number-of-employees","required":"","state":errors.length > 0 ? false:null,"disabled":_vm.disableInputs},model:{value:(_vm.number_of_employees),callback:function ($$v) {_vm.number_of_employees=$$v},expression:"number_of_employees"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"company-annual-turnover-group","label":"Annual turnover:","label-for":"company-annual-turnover-field"}},[_c('validation-provider',{attrs:{"name":"Annual turnover","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-annual-turnover-field","required":"","state":errors.length > 0 ? false:null,"disabled":_vm.disableInputs},model:{value:(_vm.annual_turnover),callback:function ($$v) {_vm.annual_turnover=$$v},expression:"annual_turnover"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"company-intent-to-sell-group","label":"How much you intend to sell?","label-for":"company-intent-to-sell"}},[_c('validation-provider',{attrs:{"name":"Intend to sell","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"company-intent-to-sell","options":_vm.intent_to_sell_values,"required":"","state":errors.length > 0 ? false:null,"disabled":_vm.disableInputs},model:{value:(_vm.intent_to_sell),callback:function ($$v) {_vm.intent_to_sell=$$v},expression:"intent_to_sell"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"company-number-of-debtors-group","label":"Total Number of Debtors","label-for":"company-number-of-debtors"}},[_c('validation-provider',{attrs:{"name":"Number of Debtors","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"company-number-of-debtors","options":_vm.number_of_debtors_values,"required":"","state":errors.length > 0 ? false:null,"disabled":_vm.disableInputs},model:{value:(_vm.number_of_debtors),callback:function ($$v) {_vm.number_of_debtors=$$v},expression:"number_of_debtors"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Open debt enforcement against you?"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"company-debt-enforcement","options":_vm.debt_enforcement_values,"aria-describedby":ariaDescribedby,"name":"debt-enforcement"},model:{value:(_vm.debt_enforcement),callback:function ($$v) {_vm.debt_enforcement=$$v},expression:"debt_enforcement"}})]}}])})],1)],1)],1),_c('h5',[_vm._v("Main Contact Person")]),_c('b-card',[_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"main-contact-gender-group","label":"Salutation:","label-for":"main-contact-gender"}},[_c('b-form-select',{attrs:{"id":"main-contact-gender","options":['Mr','Mrs'],"required":""},model:{value:(_vm.main_contact_gender),callback:function ($$v) {_vm.main_contact_gender=$$v},expression:"main_contact_gender"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"main-contact-email-group","label":"Company Email address:","label-for":"main-contact-email"}},[_c('validation-provider',{attrs:{"name":"Number of Debtors","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"main-contact-email","required":"","state":errors.length > 0 ? false:null,"disabled":_vm.disableInputs},model:{value:(_vm.main_contact_email),callback:function ($$v) {_vm.main_contact_email=$$v},expression:"main_contact_email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"main-contact-first-name-group","label":"First name:","label-for":"main-contact-first-name"}},[_c('validation-provider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"main-contact-first-name","required":"","state":errors.length > 0 ? false:null,"disabled":_vm.disableInputs},model:{value:(_vm.main_contact_first_name),callback:function ($$v) {_vm.main_contact_first_name=$$v},expression:"main_contact_first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"main-contact-tel-group","label":"Mobile phone:","label-for":"main-contact-tel"}},[_c('validation-provider',{attrs:{"name":"Mobile phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"main-contact-tel","required":"","state":errors.length > 0 ? false:null,"disabled":_vm.disableInputs},model:{value:(_vm.main_contact_tel),callback:function ($$v) {_vm.main_contact_tel=$$v},expression:"main_contact_tel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"main-contact-last-name-group","label":"Last name:","label-for":"main-contact-last-name"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"main-contact-last-name","required":"","state":errors.length > 0 ? false:null,"disabled":_vm.disableInputs},model:{value:(_vm.main_contact_last_name),callback:function ($$v) {_vm.main_contact_last_name=$$v},expression:"main_contact_last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"main-contact-position-group","label":"Position in the Company:","label-for":"main-contact-position"}},[_c('validation-provider',{attrs:{"name":"Position in the Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"main-contact-position","required":"","state":errors.length > 0 ? false:null,"disabled":_vm.disableInputs},model:{value:(_vm.main_contact_position),callback:function ($$v) {_vm.main_contact_position=$$v},expression:"main_contact_position"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"signing-authority-type-group","label":"Salutation:","label-for":"signing-authority-type"}},[_c('b-form-select',{attrs:{"id":"signing-authority-type","options":_vm.signing_authority_type_values,"required":""},model:{value:(_vm.signing_authority_type),callback:function ($$v) {_vm.signing_authority_type=$$v},expression:"signing_authority_type"}})],1)],1)],1)],1),(_vm.signators.length>0)?_c('h5',[_vm._v("Other Signers")]):_vm._e(),_vm._l((_vm.signators),function(signator,index){return _c('b-card',{staticStyle:{"background":"#fefefe"}},[_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":index+'signator-gender-group',"label":"Salutation:","label-for":index+'signator-gender'}},[_c('b-form-select',{attrs:{"id":index+'signator-gender',"options":['Mr','Mrs'],"required":""},model:{value:(signator.gender),callback:function ($$v) {_vm.$set(signator, "gender", $$v)},expression:"signator.gender"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":index+'signator-email-group',"label":"Company Email address:","label-for":index+'signator-email'}},[_c('validation-provider',{attrs:{"name":index+' Email',"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":index+'signator-email',"required":"","state":errors.length > 0 ? false:null,"disabled":_vm.disableInputs},model:{value:(signator.email),callback:function ($$v) {_vm.$set(signator, "email", $$v)},expression:"signator.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":index+'signator-first-name-group',"label":"First name:","label-for":index+'signator-first-name'}},[_c('validation-provider',{attrs:{"name":index+' First Name',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":index+'signator-first-name',"required":"","state":errors.length > 0 ? false:null,"disabled":_vm.disableInputs},model:{value:(signator.first_name),callback:function ($$v) {_vm.$set(signator, "first_name", $$v)},expression:"signator.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":index+'signator-tel-group',"label":"Mobile phone:","label-for":index+'signator-tel'}},[_c('b-form-input',{attrs:{"id":index+'signator-tel'},model:{value:(signator.tel),callback:function ($$v) {_vm.$set(signator, "tel", $$v)},expression:"signator.tel"}})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":index+'signator-last-name-group',"label":"Last name:","label-for":index+'signator-last-name'}},[_c('validation-provider',{attrs:{"name":index+' Last Name',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":index+'signator-last-name',"required":"","state":errors.length > 0 ? false:null,"disabled":_vm.disableInputs},model:{value:(signator.last_name),callback:function ($$v) {_vm.$set(signator, "last_name", $$v)},expression:"signator.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":index+'signator-position-group',"label":"Position in the Company:","label-for":index+'signator-position'}},[_c('validation-provider',{attrs:{"name":index+' Position',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":index+'signator-position',"required":"","state":errors.length > 0 ? false:null,"disabled":_vm.disableInputs},model:{value:(signator.position),callback:function ($$v) {_vm.$set(signator, "position", $$v)},expression:"signator.position"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":index+'signator-signing-authority-type-group',"label":"Signing authority type:","label-for":index+'signator-signing-authority-type'}},[_c('b-form-select',{attrs:{"id":index+'signator-signing-authority-type',"options":_vm.signing_authority_type_values,"required":""},model:{value:(signator.signing_authority_type),callback:function ($$v) {_vm.$set(signator, "signing_authority_type", $$v)},expression:"signator.signing_authority_type"}})],1)],1),_c('b-col',{staticClass:"text-right pt-2",attrs:{"cols":"6"}},[_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.deleteSignator(index)}}},[_c('feather-icon',{attrs:{"icon":"DeleteIcon"}}),_vm._v(" Delete ")],1)],1)],1)],1)}),(_vm.signators.length < 5)?_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.addNewSigner()}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" Add Signatory ")],1)],1)],1):_vm._e(),_c('h5',[_vm._v("Legal")]),_c('b-card',[_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"terms-and-conditions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"terms-and-conditions","name":"terms-and-conditions","state":errors.length > 0 ? false:null,"disabled":_vm.disableInputs},model:{value:(_vm.terms_and_conditions),callback:function ($$v) {_vm.terms_and_conditions=$$v},expression:"terms_and_conditions"}},[_vm._v(" Agree with terms & conditions "),_c('a',{attrs:{"href":"/"}},[_vm._v("(Full)")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',{staticClass:"mt-1",staticStyle:{"background":"#efefef","padding":"10px"}},[_c('p',[_vm._v(" Diese AGB's gelten. Wir machen Sie insbesondere darauf aufmerksam, dass Falschangaben strafrechtlich geahndet werden konnen. Auf folgenden Tatbestande kann die Haftung auf Sie personlich rubergehen. ")]),_c('ul',[_c('li',[_vm._v("Bei Falschungen")]),_c('li',[_vm._v("Bei XY adipiscing elit mauris")]),_c('li',[_vm._v("Bei adipiscing elit mauris")])]),_c('p',[_vm._v("Tonsectetur adipiscing elit mauris vulputate neque. Lorem ipsum dolor sit amet")])])],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"terms-and-conditions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"data-privacy","name":"data-privacy","state":errors.length > 0 ? false:null,"disabled":_vm.disableInputs},model:{value:(_vm.data_privacy),callback:function ($$v) {_vm.data_privacy=$$v},expression:"data_privacy"}},[_vm._v(" Agree with data protection ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.saveCompanyInfo()}}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" Save Company Information ")],1)],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }