<template>
  <div>
    <h3>Add company details</h3>
    <p>
      We need some basic data from you
    </p>
    <validation-observer ref="companyRegisterValidation" name="RegisterCompany">
    <b-form @submit="onSubmit" @reset="onReset">
      <b-card>
        <b-row m-0 p-0>
          <b-col cols="6">
            <b-form-group id="domicile-country-group" label="Domicile country:" label-for="domicile-country">
              <validation-provider
                  #default="{ errors }"
                  name="Country"
                  rules="required"
              >
                <b-form-select
                    id="domicile-country"
                    v-model="country_id"
                    :options="countries"
                    :state="errors.length > 0 ? false:null"
                    required
                    :disabled="disableInputs"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group id="company-name-group" label="Company Name" label-for="company-name">
                <b-input-group>
                  <template #append>
                    <b-button @click="getCompanyDataFromZefix()" v-if="companies[company_name]!=null">
                      <feather-icon
                          icon="DownloadIcon"
                      /> Get Data
                    </b-button>
                  </template>
                  <b-form-input
                      autocomplete="off"
                      @keyup="searchForCompaniesName()"
                      id="company-name"
                      v-model="company_name"
                      required
                      list="companies-list"
                      :disabled="disableInputs"
                  ></b-form-input>
                </b-input-group>
              <div class="mt-2">Company UID: "{{ companies[company_name] }}"</div>
              <datalist id="companies-list">
                <option v-for="(company, index) in companies">{{ index }}</option>
              </datalist>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <img src="/loader.svg" v-if="showLoader" />
            {{JSON.stringify(company_zefix_data)}}
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-row m-0 p-0>
          <b-col cols="6">
            <b-form-group id="company-name-field-group" label="Company name:" label-for="company-name-field">
              <validation-provider
                  #default="{ errors }"
                  name="Company Name"
                  rules="required"
              >
                <b-form-input
                    id="company-name-field"
                    v-model="company_name"
                    required
                    :state="errors.length > 0 ? false:null"
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group id="company-address-group" label="Street name:" label-for="company-address">
              <validation-provider
                  #default="{ errors }"
                  name="Company Address"
                  rules="required"
              >
                <b-form-input
                    id="company-address"
                    v-model="company_address"
                    :state="errors.length > 0 ? false:null"
                    required
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group id="company-address-nr-group" label="Nr:" label-for="company-address-nr">
              <validation-provider
                  #default="{ errors }"
                  name="Company Address Nr"
                  rules="required"
              >
                <b-form-input
                    id="company-address-nr"
                    v-model="company_address_nr"
                    required
                    :state="errors.length > 0 ? false:null"
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row m-0 p-0>
          <b-col cols="6">
            <b-form-group id="company-uid-group" label="Company UID (CHE-397.517.219 > CHE397517219):" label-for="company-uid">
              <validation-provider
                  #default="{ errors }"
                  name="Company UID"
                  rules="required"
              >
                <b-form-input
                    id="company-uid"
                    v-model="company_uid"
                    required
                    :state="errors.length > 0 ? false:null"
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group id="company-zip-group" label="Zip:" label-for="company-zip">
              <validation-provider
                  #default="{ errors }"
                  name="Company Zip"
                  rules="required"
              >
                <b-form-input
                    id="company-zip"
                    v-model="company_zip"
                    required
                    :state="errors.length > 0 ? false:null"
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group id="company-city-group" label="City:" label-for="company-city">
              <validation-provider
                  #default="{ errors }"
                  name="Company City"
                  rules="required"
              >
                <b-form-input
                    id="company-city"
                    v-model="company_city"
                    required
                    :state="errors.length > 0 ? false:null"
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row m-0 p-0>
          <b-col cols="6">
            <b-form-group id="company-legal-form-group" label="Legal Form:" label-for="company-legal-form">
              <validation-provider
                  #default="{ errors }"
                  name="Company Legal Form"
                  rules="required"
              >
                <b-form-select
                    id="company-legal-form"
                    v-model="company_legal_form"
                    :options="legal_forms"
                    required
                    :state="errors.length > 0 ? false:null"
                    :disabled="disableInputs"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group id="company-website-group" label="Website:" label-for="company-website">
              <b-form-input
                  id="company-website"
                  v-model="company_website"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <h5>Financial Data</h5>
      <b-card style="background:#fefefe;">
        <b-row m-0 p-0>
          <b-col cols="6">
            <b-form-group id="company-established-at-group" label="Established since:" label-for="company-established-at-field">
              <validation-provider
                  #default="{ errors }"
                  name="Established since"
                  rules="required"
              >
                <b-form-datepicker
                    id="company-established-at-field"
                    v-model="company_established_at"
                    :state="errors.length > 0 ? false:null"
                    :disabled="disableInputs"
                    required
                ></b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group id="company-number-of-employees-group" label="Number of employees:" label-for="company-number-of-employees">
              <validation-provider
                  #default="{ errors }"
                  name="Number of employees"
                  rules="required"
              >
                <b-form-input
                    id="company-number-of-employees"
                    v-model="number_of_employees"
                    required
                    :state="errors.length > 0 ? false:null"
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row m-0 p-0>
          <b-col cols="6">
            <b-form-group id="company-annual-turnover-group" label="Annual turnover:" label-for="company-annual-turnover-field">
              <validation-provider
                  #default="{ errors }"
                  name="Annual turnover"
                  rules="required"
              >
                <b-form-input
                    id="company-annual-turnover-field"
                    v-model="annual_turnover"
                    required
                    :state="errors.length > 0 ? false:null"
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group id="company-intent-to-sell-group" label="How much you intend to sell?" label-for="company-intent-to-sell">
              <validation-provider
                  #default="{ errors }"
                  name="Intend to sell"
                  rules="required"
              >
                <b-form-select
                    id="company-intent-to-sell"
                    v-model="intent_to_sell"
                    :options="intent_to_sell_values"
                    required
                    :state="errors.length > 0 ? false:null"
                    :disabled="disableInputs"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row m-0 p-0>
          <b-col cols="6">
            <b-form-group id="company-number-of-debtors-group" label="Total Number of Debtors" label-for="company-number-of-debtors">
              <validation-provider
                  #default="{ errors }"
                  name="Number of Debtors"
                  rules="required"
              >
                <b-form-select
                    id="company-number-of-debtors"
                    v-model="number_of_debtors"
                    :options="number_of_debtors_values"
                    required
                    :state="errors.length > 0 ? false:null"
                    :disabled="disableInputs"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Open debt enforcement against you?" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                  id="company-debt-enforcement"
                  v-model="debt_enforcement"
                  :options="debt_enforcement_values"
                  :aria-describedby="ariaDescribedby"
                  name="debt-enforcement"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <h5>Main Contact Person</h5>
      <b-card>
        <b-row m-0 p-0>
          <b-col cols="6">
            <b-form-group id="main-contact-gender-group" label="Salutation:" label-for="main-contact-gender">
              <b-form-select
                id="main-contact-gender"
                v-model="main_contact_gender"
                :options="['Mr','Mrs']"
                required
            ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group id="main-contact-email-group" label="Company Email address:" label-for="main-contact-email">
              <validation-provider
                  #default="{ errors }"
                  name="Number of Debtors"
                  rules="required|email"
              >
                <b-form-input
                    id="main-contact-email"
                    v-model="main_contact_email"
                    required
                    :state="errors.length > 0 ? false:null"
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row m-0 p-0>
          <b-col cols="6">
            <b-form-group id="main-contact-first-name-group" label="First name:" label-for="main-contact-first-name">
              <validation-provider
                  #default="{ errors }"
                  name="First name"
                  rules="required"
              >
                <b-form-input
                    id="main-contact-first-name"
                    v-model="main_contact_first_name"
                    required
                    :state="errors.length > 0 ? false:null"
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group id="main-contact-tel-group" label="Mobile phone:" label-for="main-contact-tel">
              <validation-provider
                  #default="{ errors }"
                  name="Mobile phone"
                  rules="required"
              >
                <b-form-input
                    id="main-contact-tel"
                    v-model="main_contact_tel"
                    required
                    :state="errors.length > 0 ? false:null"
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row m-0 p-0>
          <b-col cols="6">
            <b-form-group id="main-contact-last-name-group" label="Last name:" label-for="main-contact-last-name">
              <validation-provider
                  #default="{ errors }"
                  name="Last Name"
                  rules="required"
              >
                <b-form-input
                    id="main-contact-last-name"
                    v-model="main_contact_last_name"
                    required
                    :state="errors.length > 0 ? false:null"
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group id="main-contact-position-group" label="Position in the Company:" label-for="main-contact-position">
              <validation-provider
                  #default="{ errors }"
                  name="Position in the Company"
                  rules="required"
              >
                <b-form-input
                    id="main-contact-position"
                    v-model="main_contact_position"
                    required
                    :state="errors.length > 0 ? false:null"
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row m-0 p-0>
          <b-col cols="6">
            <b-form-group id="signing-authority-type-group" label="Salutation:" label-for="signing-authority-type">
              <b-form-select
                id="signing-authority-type"
                v-model="signing_authority_type"
                :options="signing_authority_type_values"
                required
            ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <h5 v-if="signators.length>0">Other Signers</h5>
      <b-card style="background:#fefefe;" v-for="(signator, index) in signators">
        <b-row m-0 p-0>
          <b-col cols="6">
            <b-form-group :id="index+'signator-gender-group'" label="Salutation:" :label-for="index+'signator-gender'">
              <b-form-select
                  :id="index+'signator-gender'"
                  v-model="signator.gender"
                  :options="['Mr','Mrs']"
                  required
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group :id="index+'signator-email-group'" label="Company Email address:"  :label-for="index+'signator-email'">
              <validation-provider
                  #default="{ errors }"
                  :name="index+' Email'"
                  rules="required|email"
              >
                <b-form-input
                    :id="index+'signator-email'"
                    v-model="signator.email"
                    required
                    :state="errors.length > 0 ? false:null"
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row m-0 p-0>
          <b-col cols="6">
            <b-form-group :id="index+'signator-first-name-group'" label="First name:" :label-for="index+'signator-first-name'">
              <validation-provider
                  #default="{ errors }"
                  :name="index+' First Name'"
                  rules="required"
              >
                <b-form-input
                    :id="index+'signator-first-name'"
                    v-model="signator.first_name"
                    required
                    :state="errors.length > 0 ? false:null"
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group :id="index+'signator-tel-group'" label="Mobile phone:" :label-for="index+'signator-tel'">
              <b-form-input
                  :id="index+'signator-tel'"
                  v-model="signator.tel"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row m-0 p-0>
          <b-col cols="6">
            <b-form-group :id="index+'signator-last-name-group'" label="Last name:" :label-for="index+'signator-last-name'">
              <validation-provider
                  #default="{ errors }"
                  :name="index+' Last Name'"
                  rules="required"
              >
                <b-form-input
                    :id="index+'signator-last-name'"
                    v-model="signator.last_name"
                    required
                    :state="errors.length > 0 ? false:null"
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group :id="index+'signator-position-group'" label="Position in the Company:" :label-for="index+'signator-position'">
              <validation-provider
                  #default="{ errors }"
                  :name="index+' Position'"
                  rules="required"
              >
                <b-form-input
                    :id="index+'signator-position'"
                    v-model="signator.position"
                    required
                    :state="errors.length > 0 ? false:null"
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row m-0 p-0>
          <b-col cols="6">
            <b-form-group :id="index+'signator-signing-authority-type-group'" label="Signing authority type:"  :label-for="index+'signator-signing-authority-type'">
              <b-form-select
                  :id="index+'signator-signing-authority-type'"
                  v-model="signator.signing_authority_type"
                  :options="signing_authority_type_values"
                  required
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="6" class="text-right pt-2">
            <b-button variant="outline-danger" @click="deleteSignator(index)">
              <feather-icon icon="DeleteIcon"></feather-icon>
              Delete
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-row m-0 p-0 v-if="signators.length < 5">
        <b-col cols="12" class="text-right">
          <b-button variant="outline-primary" @click="addNewSigner()">
            <feather-icon icon="PlusIcon"></feather-icon> Add Signatory
          </b-button>
        </b-col>
      </b-row>
      <h5>Legal</h5>
      <b-card>
        <b-row m-0 p-0>
          <b-col cols="12">

            <!-- terms-and-conditions -->
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  name="terms-and-conditions"
                  rules="required"
              >
                <b-form-checkbox
                    id="terms-and-conditions"
                    v-model="terms_and_conditions"
                    name="terms-and-conditions"
                    :state="errors.length > 0 ? false:null"
                    :disabled="disableInputs"
                >
                  Agree with terms & conditions <a href="/">(Full)</a>
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <div style="background:#efefef;padding:10px;" class="mt-1">
                <p>
                  Diese AGB's gelten. Wir machen Sie insbesondere darauf aufmerksam, dass Falschangaben strafrechtlich geahndet werden konnen. Auf folgenden Tatbestande kann die Haftung auf Sie personlich rubergehen.
                </p>
                <ul>
                  <li>Bei Falschungen</li>
                  <li>Bei XY adipiscing elit mauris</li>
                  <li>Bei adipiscing elit mauris</li>
                </ul>
                <p>Tonsectetur adipiscing elit mauris vulputate neque. Lorem ipsum dolor sit amet</p>
              </div>
            </b-form-group>

            <!-- remember-me -->
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  name="terms-and-conditions"
                  rules="required"
              >
                <b-form-checkbox
                    id="data-privacy"
                    v-model="data_privacy"
                    name="data-privacy"
                    :state="errors.length > 0 ? false:null"
                    :disabled="disableInputs"
                >
                  Agree with data protection
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
        </b-row>
      </b-card>
      <b-row m-0 p-0>
        <b-col cols="12" class="text-right">
          <b-button variant="primary" @click="saveCompanyInfo()">
            <feather-icon icon="SaveIcon"></feather-icon> Save Company Information
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BButton,
  BFormDatepicker,
  BFormRadio,
  BFormRadioGroup,
  BFormCheckbox
} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BFormDatepicker,
    BFormRadio,
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      showLoader: false,
      disableInputs: false,
      country_id: 1,
      countries: [],
      company_id: this.$store.state.auth.user.company_id,
      company_name: null,
      companies: [],
      company_zefix_data: null,
      company_address: null,
      company_address_nr: null,
      company_uid: null,
      company_zip: null,
      company_city: null,
      company_legal_form: null,
      company_website: null,
      legal_forms:['Aktiengesellschaft', 'Gesellschaft mit beschränkter Haftung', 'Einzelunternehmen'],
      company_established_at: null,
      number_of_employees: 0,
      annual_turnover: 0,
      intent_to_sell: '1% - 10%',
      number_of_debtors: '1-10',
      debt_enforcement: 'Yes',
      intent_to_sell_values: ['1% - 10%', '11% - 20%', '21% - 30%', '31% - 50%', '51% - 70%', '71% - 80%', '81% - 90%', '91% - 100%'],
      number_of_debtors_values: ['1-10', '11-20', '21-50', '51-100', '101-200', '201-500', '500+'],
      debt_enforcement_values: ['Yes','No'],
      main_contact_gender: 'Mr',
      main_contact_email: this.$store.state.auth.user.email,
      main_contact_first_name: this.$store.state.auth.user.first_name,
      main_contact_last_name: this.$store.state.auth.user.last_name,
      main_contact_tel: null,
      main_contact_position: null,
      signing_authority_type: 'Collectively by two',
      signing_authority_type_values: ['Sole signatory', 'Collectively by two', 'Only Contact person'],
      signators: [
        {
          gender: 'Mr',
          first_name: null,
          email: null,
          last_name: null,
          tel: null,
          position: null,
          signing_authority_type: 'Collectively by two',
        }
      ],
      terms_and_conditions: '',
      data_privacy: '',
    }
  },
  name: "AddCompany",
  created(){
      this.$http.post('/countries').then(res=>{
        res.data.value.map((item)=>{
          this.countries.push({
            value: item.id,
            text: item.name
          });
        });
      });
  },
  methods: {
    saveCompanyInfo(){

      this.$refs.companyRegisterValidation.validate().then(success => {
        if (success) {
          this.disableInputs = true;
          const formSubmissionData = {
            company_id: this.company_id,
            company_name: this.company_name,
            company_address: this.company_address,
            company_address_nr: this.company_address_nr,
            company_uid: this.company_uid,
            company_zip: this.company_zip,
            company_city: this.company_city,
            company_country_id: this.country_id,
            company_website: this.company_website,
            main_contact_person_gender: this.main_contact_gender,
            main_contact_first_name: this.main_contact_first_name,
            main_contact_last_name: this.main_contact_last_name,
            main_contact_position: this.main_contact_position,
            main_contact_tel: this.main_contact_tel,
            signing_authority_type: this.signing_authority_type,
            established_at: this.established_at,
            number_of_employees: this.number_of_employees,
            annual_turnover: this.annual_turnover,
            intent_to_sell: this.intent_to_sell,
            number_of_debtors: this.number_of_debtors,
            debt_enforcement: this.debt_enforcement,
            signers: this.signators.length,
            terms_and_conditions: this.terms_and_conditions,
            data_privacy: this.data_privacy,
            placeholder: 1,
          }
          let data = {};
          this.signators.map((item, i)=>{
            const nr = i+1;
            const signator = {
              ["signator_" + nr + "_gender"]: item.gender,
              ["signator_"+nr+"_first_name"]: item.first_name,
              ["signator_"+nr+"_last_name"]: item.last_name,
              ["signator_"+nr+"_email"]: item.email,
              ["signator_"+nr+"_tel"]: item.tel,
              ["signator_"+nr+"_position"]: item.position,
              ["signator_"+nr+"_signing_authority_type"]: item.signing_authority_type
            }
            data = {...formSubmissionData, ...signator};
          });

          this.$http.post('/company/store', data).then(res=> {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Company registered successfully',
                icon: 'SuccessIcon',
                variant: 'success',
              },
            });
            this.$router.push('/');
          })

        }

        this.disableInputs = false;
      });
    },
    deleteSignator(index){
        this.signators.splice(index, 1);
    },
    searchForCompaniesName(){
        if(this.company_name.length > 2){
          this.$http.post('/get-companies-name-from-zefix', {search: this.company_name}).then(res=> {
            this.companies = res.data.value
          })
        }
    },
    getCompanyDataFromZefix(){
      this.showLoader = true;
      if(typeof this.companies[this.company_name] != 'undefined'){
        this.$http.post('/get-company-data-from-zefix', {search: this.companies[this.company_name]}).then(res=> {
          this.company_zefix_data = res.data.value;
          this.company_uid = this.company_zefix_data.uid;
          this.company_address = this.company_zefix_data.address.street;
          this.company_address_nr = this.company_zefix_data.address.houseNumber;
          this.company_city = this.company_zefix_data.address.city;
          this.company_zip = this.company_zefix_data.address.swissZipCode;
          this.company_legal_form = this.company_zefix_data.legalForm.name.de;
          this.company_established_at = this.company_zefix_data.sogcDate;
          this.showLoader = false;
        })
      }
    },
    addNewSigner(){
      if(this.signators.length < 5) {
        this.signators.push(
            {
              gender: 'Mr',
              first_name: null,
              email: null,
              last_name: null,
              tel: null,
              position: null,
              signing_authority_type: null,
            }
        );
      }else{
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'You can add only up to 5 Signatory!',
            icon: 'ErrorIcon',
            variant: 'error',
          },
        })
      }
    },
    onSubmit(event) {
      event.preventDefault()
      alert(JSON.stringify(this.form))
    },
    onReset(event) {
      event.preventDefault()
      this.country_id = 0;
      this.countries = [];
    }
  }
}
</script>

<style scoped>

</style>
